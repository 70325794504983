<template>
  <div class="image_component">
    <img
      :src="src"
      :alt="alt"
      :loading="testing ? undefined : 'lazy'"
      :width="width"
      :height="height"
    />
  </div>
</template>

<script>
export default {
  name: 'ImageComponent',
  props: {
    src: {
      type: String,
      required: true
    },
    alt: {
      type: String,
      default: ''
    },
    width: {
      type: [String, Number],
      default: undefined
    },
    height: {
      type: [String, Number],
      default: undefined
    }
  },
  data() {
    return {
      testing: window.__STORYBOOK_ADDONS || window.Cypress ? true : false
    }
  }
}
</script>

<style lang="scss" scoped>
.image_component {
  display: flex;

  img {
    align-self: center;
    max-width: 100%;
    object-fit: contain;
  }
}
</style>
